import axios from 'axios'
import { getData } from 'services/storage'

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY
// const BASE_URL = 'http://localhost:3333/'
const BASE_URL = 'https://api.bestbarbers.app/'
// const BASE_URL = 'https://bestbarbers-api-homologation-e915.up.railway.app/'
// const BASE_URL = null

const api = axios.create({
  baseURL: BASE_URL || 'https://api.bestbarbers.app',
})

api.interceptors.request.use(
  async (config) => {
    const result = localStorage.getItem('USER')
    const userData = await getData('@BestBarbers:userData')
    const user = await getData('@BestBarbers:user')
    // if (userData?.version === 'v3') {
    //   config.baseURL = BASE_URL || 'https://api.prod.bestbarbers.app'
    // }
    if (result?.token) {
      config.headers.Authorization = `Bearer ${result?.token}`
    }
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

export { api, GOOGLE_MAPS_API_KEY }
