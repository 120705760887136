import React, { useEffect, useMemo, useState } from 'react'
import { formatAppointmentTime, formatPrice } from 'utils'
import { format, parseISO, differenceInDays, subHours } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { useAppointmentDetailStore, useCustomerStore } from 'services/stores'
import { Flex, useTheme, Box, useToast, Select, Center } from '@chakra-ui/react'
import { Text, Icon, Button, TextInput, Link, Loader } from 'components/atoms'
import {
  DateTimeCard,
  BarberCard,
  ServiceCard,
  PaymentMethodCard,
  AdditionalProductCard,
  ModalAlert,
  ClientCard,
  SourceCard,
  SelectCard,
  ObservationCard,
} from 'components/molecules'
import {
  AppointmentsModal,
  ClientDetailModal,
  ClientObservationsModal,
  UpdateAppointmentId,
  IssuerInvoiceForm,
} from 'components/organisms'
import { useBreakpoint, usePermissionCollaborator, useBarbershop, useAppointment } from 'services/hooks'
import { APPOINTMENT_DETAILS_STEPS } from './settings'
import { useAppointmentStore, useBarbershopStore, useInvoiceData } from 'services/stores'
import { additionalProductsStore } from 'services/stores/additionalProducts'
import { t } from 'services/translation'

import { useMutation, useQuery, useQueryClient } from 'react-query'
import { api } from 'services/api/config'
import { FINISH_APPOINTMENT, DELETE_APPOINTMENT } from 'services/api/endpoints'
import { usePremiumBlock } from 'services/hooks/use-premium-block'
import { formatCurrency } from 'utils/formatCurrency'
import { handleGetStatus, subtitle_props, calculated_info } from './details_help'
import { getData } from 'services/storage'
import { useInvoice, useInvoiceIssuer } from 'services/hooks'
import { formatNFCeParams, formatNFSeParams } from 'utils'
import { useAppointmentDetails } from 'services/hooks/use-appointment-details'

export const DetailsOptions = ({ setStep, onClose, selectedDate, app, setEnabled, setAppointment, enabled }) => {
  const {
    selectedAppointment,
    additional,
    paymentMethod,
    setPaymentMethod,
    discount,
    setDiscount,
    setSelectedAppointment,
    normal,
    setNormal,
  } = useAppointmentDetailStore()
  const [observationsModalVisible, setObservationsModalVisible] = useState(false)
  const { userData } = useBarbershopStore()
  const [appointmentEmission, setAppointmentEmission] = useState({
    nfce: userData?.nfce_active,
    nfse: userData?.nfse_active,
  })

  const {
    setType,
    setClient,
    setBarber,
    setFinishHour,
    setServiceArray,
    setDate,
    setTime,
    setSource,
    source,
    sourcesList,
    clearState,
    onReopen,
    serviceArray,
    productsList,
    setProductsList,
    setSignature,
  } = useAppointmentStore()
  const { additionalProductsAppointmentList, setAdditionalProductsAppointmentList } = additionalProductsStore()
  const { selectedCustomer, setSelectedCustomer } = useCustomerStore()
  const [isCustomerDetailModalOpen, setCustomerDetailModal] = useState(false)
  const [cancelModalOpen, setCancelModalOpen] = useState(false)
  const [alertModalOpen, setAlertModalOpen] = useState(false)
  const [credit, setCredit] = useState(selectedAppointment?.client_balance?.toString()?.replace('-', ''))
  const [balanceClient, setBalanceClient] = useState({
    credit: selectedAppointment?.client_balance_description === 'credit' ? true : false,
    debit: selectedAppointment?.client_balance_description === 'debit' ? true : false,
    to_charge: selectedAppointment?.client_balance_description === 'to_charge' ? true : false,
  })
  const [isEditing, setIsEditing] = useState(false)
  const theme = useTheme()
  const toast = useToast()
  const { isDesktop } = useBreakpoint()
  const isBlocked = usePremiumBlock()
  const appointment = usePermissionCollaborator('appointment')
  const reopen_permission = usePermissionCollaborator('reopen')
  const clients = usePermissionCollaborator('clients')
  const [inputValue, setInputValue] = useState([])
  const [modalIdOpen, setModalIdOpen] = useState(false)
  const { finishReopenedAppointment, getObservations } = useBarbershop()
  const { getAppointmentDetails, getRecurrentAppointmentDetails } = useAppointmentDetails()
  const [enabledServices, setEnabledServices] = useState(true)
  const queryClient = useQueryClient()
  const {
    getAppointmentProducts,
    removeAppointmentProduct,
    updateAppointmentStatus,
    cancelRecurrent,
    deleteRecurrent,
    confirmRecurrent,
    getServiceAppointment,
    deleteServiceAppointment,
  } = useAppointment()

  const { isLoading } = useQuery(
    ['get-appointment-details', app],
    () =>
      app
        ? app?.repeat_every_week_day
          ? getRecurrentAppointmentDetails({ id: app.id })
          : getAppointmentDetails({ id: app.id })
        : null,
    {
      onSuccess: (data) => {
        setSelectedAppointment(data)
      },
      enabled,
    }
  )
  const {
    data: observations,
    isLoading: loadingObservations,
    refetch: refetchObservations,
  } = useQuery(['get-observations', selectedAppointment?.id], () =>
    !selectedAppointment
      ? null
      : getObservations({
          barbershop_id: userData?.barbershop?.id || userData?.id,
          client_id: selectedAppointment?.client_id,
        })
  )

  const { refetch, isLoading: loadingProducts } = useQuery(
    ['get-add-additional-products', selectedAppointment?.id],
    () =>
      !selectedAppointment
        ? null
        : getAppointmentProducts({
            appointment_id: selectedAppointment?.id,
            onSuccess: (value) => setAdditionalProductsAppointmentList(value),
          }),
    {
      enabled: enabledServices,
    }
  )
  // const { refetch: refetchServices, isLoading: loadingServices } = useQuery(
  //   ['get-services-appointment', selectedAppointment],
  //   () =>
  //     !selectedAppointment
  //       ? null
  //       : getServiceAppointment({
  //           appointment_id: selectedAppointment?.id,
  //           onSuccess: (value) => {
  //             console.log('aqui', value)
  //             setSelectedAppointment({
  //               ...selectedAppointment,
  //               formatted_services: value,
  //             })
  //           },
  //         }),
  //   {
  //     enabled: enabledServices,
  //   }
  // )

  const onSuccess = () => {
    onClose(false)
    setAdditionalProductsAppointmentList([])
    clearState()
    setDiscount(null)
    setNormal(true)
    setAppointment(null)
    setSelectedAppointment(null)
  }

  const remove_product_mutation = useMutation('remove-appointment-product', (appointment_product_id) =>
    removeAppointmentProduct({
      appointment_product_id,
      onSuccess: () => refetch(),
    })
  )

  const remove_service_mutation = useMutation(
    'remove-appointment-product',
    (params) =>
      deleteServiceAppointment({
        id: params?.id,
        onSuccess: () => {
          const newArr = selectedAppointment?.formatted_services?.filter(
            (i) => i?.appointment_service_id !== params?.id && i?.id !== params?.id
          )
          setSelectedAppointment({
            ...selectedAppointment,
            formatted_services: newArr,
          })
        },
      }),
    {
      onSettled: () => {
        queryClient.invalidateQueries('get-services-appointment')
      },
    }
  )

  const mutateException = useMutation(
    'cancel-appointment',
    () =>
      cancelRecurrent({
        id: selectedAppointment?.id,
        params: { date: `${format(selectedDate, 'yyyy-MM-dd')}T03:00:00` },
      }),
    {
      onSuccess: () => {
        onSuccess()
      },
    }
  )

  const mutateDeleteRecurrency = useMutation(
    'cancel-appointment',
    () => deleteRecurrent({ id: selectedAppointment?.id }),
    {
      onSuccess: () => {
        onSuccess()
      },
    }
  )

  async function finishAppointment(params) {
    const res = await api
      .post(FINISH_APPOINTMENT(selectedAppointment?.id), params)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const mutateFinished = useMutation('finish-appointment', (params) => finishAppointment(params), {
    onSuccess: () => {
      submitInvoice()
      onSuccess()
      toast({
        title: t('FINISHED_APPOINTMENT'),
        status: 'success',
        duration: 4000,
        isClosable: true,
      })
    },
    onError: (error) => {
      toast({
        title: t('FINISH_APPOINTMENT_ERROR'),
        description: error.toString().substring(7),
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    },
  })

  const mutateFinishedReopened = useMutation('finish-reopened-appointment', (params) =>
    finishReopenedAppointment(params)
  )

  async function deleteAppointment(params) {
    const res = await api
      .delete(DELETE_APPOINTMENT(selectedAppointment?.id))
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw Error(error.response.data.error)
      })
    return res
  }

  const mutateDeleteAppointment = useMutation('delete-appointment', (params) => deleteAppointment(params), {
    onSuccess: () => {
      toast({
        title: t('CANCELED_APPOINTMENT'),
        status: 'success',
        duration: 4000,
        isClosable: true,
      })
      onClose(false)
      setAdditionalProductsAppointmentList([])
      setSelectedAppointment(null)
      setAppointment(null)
    },
    onError: (error) => {
      toast({
        title: t('CANCEL_APPOINTMENT_ERROR'),
        description: error.toString().substring(7),
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    },
  })

  const paymentDate = (date) => {
    if (!date) {
      return t('PAYMENT_PENDING')
    }
    if (differenceInDays(parseISO(date), new Date()) <= 0) {
      return `${t('PAYMENT_DONE')} ${format(parseISO(date), 'dd/MM/yyyy')}`
    } else {
      return `${t('PAYMENT_DATE_DESC')} ${format(parseISO(date), 'dd/MM/yyyy')}`
    }
  }

  const services = useMemo(
    () => selectedAppointment?.formatted_services || selectedAppointment?.services || selectedAppointment?.product,
    [selectedAppointment]
  )
  useEffect(() => {
    if (selectedAppointment?.discount !== 0) {
      if (
        (selectedAppointment?.formatted_services?.reduce((acc, val) => acc + val?.discount, 0) > 0 ||
          selectedAppointment?.formatted_products?.reduce((acc, val) => acc + val?.discount, 0) > 0) &&
        selectedAppointment?.status !== 'reopened'
      ) {
        setNormal(false)
        const arr = []
        selectedAppointment?.formatted_services?.forEach((i) => {
          arr.push({
            name: i.name,
            discount_value: i?.discount,
          })
        })
        selectedAppointment?.formatted_products?.forEach((i) => {
          arr.push({
            name: i.name,
            discount_value: i?.discount,
          })
        })
        setDiscount(arr)
      } else {
        setNormal(true)
      }
    }
  }, [selectedAppointment])

  const addServiceValue = useMemo(() => {
    return (
      (selectedAppointment?.status === 'reopened' ? serviceArray : services)
        ?.filter((i) => i?.type === 'normal')
        ?.reduce((acumulador, item) => {
          return acumulador + Number(item?.price || item?.product?.price || item?.item_value || 0)
        }, 0) || 0
    )
  }, [serviceArray, selectedAppointment, services])

  const discount_value = useMemo(() => {
    if (selectedAppointment?.status === 'finished') {
      return selectedAppointment?.discount
    }
    if (normal) {
      return discount?.discount
    }
    return discount
      ?.filter?.((i) => i?.discount_value)
      ?.reduce?.((acc, val) => acc + Number(val?.discount_value) || 0, 0)
  }, [discount])

  const sub_total_value = useMemo(() => {
    if (selectedAppointment?.repeat_every_week_day) {
      return services?.reduce((acc, val) => acc + Number(val?.price), 0)
    }
    return (
      (selectedAppointment?.status === 'reopened' ? productsList : additionalProductsAppointmentList)?.reduce(
        (totalValue, item) =>
          totalValue +
          Number(
            item?.item_value ||
              item?.service_value ||
              item?.product_value ||
              item?.additional_product?.price ||
              item?.additional_product?.value ||
              item?.price
          ),
        0
      ) +
      addServiceValue -
      (selectedAppointment?.status === 'reopened'
        ? discount_value || 0
        : selectedAppointment?.discount || discount_value || 0) +
      ((selectedAppointment?.client_balance_description === 'to_charge' &&
        selectedAppointment?.status !== 'reopened') ||
      balanceClient?.to_charge
        ? Number(
            (selectedAppointment?.client?.barbershop_client?.[0]?.client_balance > 0 ? -credit : credit) ||
              selectedAppointment?.client_balance
          )
        : 0) +
      (selectedAppointment?.client_balance_description === 'credit' || balanceClient?.credit ? Number(credit) : 0) +
      (selectedAppointment?.client_balance_description === 'debit' || balanceClient?.debit ? Number(-credit) : 0)
    )
  }, [
    additionalProductsAppointmentList,
    selectedAppointment,
    discount,
    addServiceValue,
    credit,
    balanceClient,
    productsList,
  ])
  const total_value = useMemo(() => {
    if (selectedAppointment?.total_after_discounts && selectedAppointment?.status === 'finished') {
      return selectedAppointment?.total_after_discounts
    }
    return Number(
      (
        (selectedAppointment?.status === 'reopened' ? productsList : additionalProductsAppointmentList).reduce(
          (totalValue, item) =>
            totalValue +
            Number(
              item?.item_value ||
                item?.service_value ||
                item?.additional_product?.price ||
                item?.additional_product?.value ||
                item?.price
            ),
          0
        ) +
        addServiceValue -
        (selectedAppointment?.status === 'reopened'
          ? discount_value || 0
          : selectedAppointment?.discount || discount_value || 0) -
        (selectedAppointment?.status === 'finished'
          ? selectedAppointment?.barber_percentage_value +
            selectedAppointment?.barber_products_value +
            (selectedAppointment?.payment_percentage_value || 0)
          : 0) +
        ((selectedAppointment?.client_balance_description === 'to_charge' &&
          selectedAppointment?.status !== 'reopened') ||
        balanceClient?.to_charge
          ? Number(
              (selectedAppointment?.client?.barbershop_client?.[0]?.client_balance > 0 ? -credit : credit) ||
                selectedAppointment?.client_balance
            )
          : 0) +
        (selectedAppointment?.client_balance_description === 'credit' || balanceClient?.credit ? Number(credit) : 0) +
        (selectedAppointment?.client_balance_description === 'debit' || balanceClient?.debit ? Number(-credit) : 0)
      ).toFixed(2)
    )
  }, [
    additionalProductsAppointmentList,
    discount_value,
    selectedAppointment,
    discount,
    addServiceValue,
    credit,
    productsList,
    balanceClient,
  ])

  const totalValue = useMemo(() => {
    const valorDosInputs = Number(
      inputValue.reduce((accumulator, currentValue) => accumulator + currentValue?.value, 0).toFixed(2)
    )
    return valorDosInputs
  }, [inputValue])

  const handleChange = (id, value) => {
    const existingItem = inputValue.find((item) => item.id === id)
    if (existingItem) {
      const updatedInputValue = inputValue.map((item) =>
        item.id === id ? { ...item, value: Number(value.replace(',', '.')) } : item
      )
      setInputValue(updatedInputValue)
    } else {
      setInputValue([...inputValue, { id, value: Number(value.replace(',', '.')) }])
    }
  }

  const onClickFinish = () => {
    if (selectedAppointment?.status === 'reopened') {
      const params = {
        payment_method:
          balanceClient?.debit && total_value <= 0
            ? [{ id: 'Saldo do cliente', value: Number(credit) }]
            : paymentMethod?.length > 1
            ? inputValue
            : [
                {
                  id: paymentMethod?.[0]?.payment_method_id || paymentMethod?.[0]?.id,
                  value: total_value,
                },
              ],
        source: selectedAppointment?.source || source?.value,
        services: serviceArray,
        products: productsList,
      }
      if (discount_value > 0) {
        params.discount = discount_value
        params.discount_observation = discount?.discount_observation
        params.discount_from_barber = discount?.discount_from_barber
      }
      if (
        (!Object.values(balanceClient)?.every((i) => !i) && credit?.length > 0) ||
        selectedAppointment?.status === 'reopened'
      ) {
        params.manager_credit = {
          client_balance_description: Object.keys(balanceClient).find((chave) => balanceClient[chave]),
          credit_balance: Number(credit?.replace('-', '')),
          barbershop_client_id: selectedAppointment?.client?.barbershop_client?.[0]?.id,
        }
      }
      mutateFinishedReopened.mutate({
        params,
        onSuccess,
        id: selectedAppointment?.id,
      })
      return
    }
    let data = {
      payment_method:
        selectedAppointment?.type === 'servicePackage' && total_value === 0
          ? [{ id: 'Pacote de serviço', value: 0 }]
          : balanceClient?.debit && total_value <= 0
          ? [{ id: 'Saldo do cliente', value: Number(credit) }]
          : paymentMethod?.length > 1
          ? inputValue
          : [{ id: paymentMethod?.[0].id, value: total_value }],
      additional_products: additional,
      source: selectedAppointment?.source || source?.value,
      services:
        selectedAppointment?.type === 'signature' || selectedAppointment?.type === 'servicePackage'
          ? services?.map((i) => {
              return {
                ...i,
                appointment_id: selectedAppointment?.id,
              }
            })
          : selectedAppointment?.formatted_services,
      appointmentEmission,
    }

    if (normal) {
      data = {
        ...data,
        ...discount,
      }
    } else {
      data.discount = discount
    }

    if (!Object.values(balanceClient)?.every((i) => !i) && credit?.length > 0) {
      data.manager_credit = {
        client_balance_description: Object.keys(balanceClient).find((chave) => balanceClient[chave]),
        credit_balance: Number(credit?.replace('-', '')),
        barbershop_client_id: selectedAppointment?.client?.barbershop_client?.[0]?.id,
      }
    }
    mutateFinished.mutate(data)
  }
  const name = (source) => {
    let value
    if (source === 'NOT_INFORMED') {
      return t()
    }
    if (typeof source !== 'object') {
      value = sourcesList.filter((item) => item.value === source)
      return value?.[0]?.name
    } else {
      value = source?.name
      return value
    }
  }

  const onClickClient = () => {
    if (clients !== 'blocked') {
      setSelectedCustomer(selectedAppointment?.client)
      setCustomerDetailModal(true)
    } else {
      toast({
        title: t('PERMISSION_DENIED'),
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    }
  }
  const onRemoveProduct = (item) => {
    if (appointment === 'edit' && selectedAppointment?.status === 'reopened') {
      setProductsList(productsList?.filter((i) => i?.id !== item?.id))
      return
    }
    if (selectedAppointment?.status !== 'finished') {
      remove_product_mutation.mutate(item?.id)
    }
  }

  const handleSelectCredit = (key, value) => {
    setBalanceClient({
      credit: false,
      debit: false,
      to_charge: false,
      [key]: value,
    })
    setCredit(null)
  }

  const statusOptions = [
    {
      value: 'finished',
      text: 'Finalizado',
      color: 'successDark',
    },
    {
      value: 'reopened',
      text: 'Reaberto',
      color: 'primary',
    },
    {
      value: 'pending',
      text: 'Pendente',
      color: 'danger',
    },
    {
      value: 'confirmed',
      text: 'Confirmado',
      color: 'blue',
    },
    {
      value: 'client_missed',
      text: 'Cliente faltou',
      color: 'textMedium',
    },
    {
      value: 'canceled_at',
      text: 'Cancelado',
      color: 'textMedium',
    },
    {
      value: 'client_arrived',
      text: 'Cliente chegou',
      color: 'orange',
    },
    {
      value: 'awaiting_payment',
      text: 'Aguardando pagamento',
      color: 'orange',
    },
  ]

  const recurrentOptions = [
    {
      value: 'pending',
      text: 'Pendente',
      color: 'danger',
    },
    {
      value: 'confirmed',
      text: 'Confirmado',
      color: 'blue',
    },
  ]

  const onSuccessUpdate = (data) => {
    if (
      data?.status === 'client_arrived' &&
      (userData?.barbershop?.user_id === 240194 || userData?.user_id === 240194)
    ) {
      setModalIdOpen(true)
    }
  }

  const mudate_updateAppointmentStatus = useMutation('confirm-appointment', (status) =>
    selectedAppointment?.repeat_every_week_day
      ? confirmRecurrent({
          params: { id: selectedAppointment?.id, date: selectedDate },
          onSuccess,
        })
      : updateAppointmentStatus({
          appointment_id: selectedAppointment?.id,
          status,
          onSuccess: onSuccessUpdate,
        })
  )
  const invalidDiscount = useMemo(() => {
    const discount_val =
      selectedAppointment?.status === 'reopened' ? discount_value : selectedAppointment?.discount || discount_value
    if (!discount_val) {
      return false
    }

    if (total_value + discount_val >= discount_val) {
      return false
    }
    return true
  }, [selectedAppointment?.status, discount_value, selectedAppointment?.discount, total_value])

  const isButtonDisabled = useMemo(() => {
    if (mutateFinished.isLoading) {
      return true
    }
    if (mutateFinishedReopened.isLoading) {
      return true
    }
    if (balanceClient?.debit && total_value <= 0) {
      return false
    }
    if (paymentMethod?.length === 0) {
      return true
    }
    if (paymentMethod?.length > 1 && totalValue !== total_value) {
      return true
    }
    if (invalidDiscount) {
      return true
    }
  }, [balanceClient, mutateFinished, mutateFinishedReopened, totalValue, total_value, paymentMethod])

  // NOTA FISCAL
  const servicesList = selectedAppointment?.status === 'reopened' ? serviceArray : services
  const appointment_id = selectedAppointment?.id
  const { getAllInvoice } = useInvoice()
  const { getAllInvoiceIssuer } = useInvoiceIssuer()
  const { setInvoiceServiceData, invoiceErrorsLength, setInvoiceData, submitInvoice } = useInvoiceData()
  const invoiceQuery = useQuery(['invoice-records', { appointment_id }], () => getAllInvoice({ appointment_id }), {
    enabled: !!appointment_id,
    select: (res) => res?.data?.[0],
  })
  const [userLogged, setUserLogged] = useState({})
  const invoiceIssuerQuery = useQuery(
    ['invoice-issuers', userLogged?.id, userLogged?.barbershop_id],
    () =>
      getAllInvoiceIssuer(
        userLogged?.barbershop_id ? { barbershop_id: userLogged?.barbershop_id } : { user_id: userLogged.id }
      ),

    {
      enabled: !!userLogged?.id,
      select: (res) => res.data?.[0],
      refetchOnWindowFocus: false,
      onSuccess: () => {
        handleIssuerInvoice()
      },
    }
  )
  const handleIssuerInvoice = () => {
    const products_list =
      discount?.length > 0 && discount?.filter((d) => !!d?.product_id)?.length > 0
        ? discount?.filter((d) => !!d?.product_id)
        : additionalProductsAppointmentList
    if (additionalProductsAppointmentList?.length > 0) {
      setInvoiceData(
        formatNFCeParams({
          type: 'finishAppointment',
          products_list,
          client: selectedAppointment?.client,
          payments: paymentMethod,
          discount: discount?.length > 0 ? null : discount?.discount,
          total_value,
          appointment_id: selectedAppointment?.id,
          invoiceIssuerQuery,
        })
      )
    }
    const services_list =
      discount?.length > 0 && discount?.filter((d) => !!d?.service_id)?.length > 0
        ? discount?.filter((d) => !!d?.service_id)
        : servicesList
    if (invoiceIssuerQuery.data?.nfse_emission && servicesList?.length > 0) {
      setInvoiceServiceData(
        formatNFSeParams({
          type: 'finishAppointment',
          invoiceIssuerQuery,
          services_list,
          client: selectedAppointment?.client,
          discount: discount?.length > 0 ? null : discount?.discount,
          total_value,
          appointment_id: selectedAppointment?.id,
        })
      )
    }
  }
  useEffect(() => {
    const initUserLogged = async () => {
      const user = await getData('@BestBarbers:user')
      const userData = await getData('@BestBarbers:userData')
      setUserLogged({ ...user, barbershop_id: userData?.barbershop_id || userData?.id, userData })
    }
    initUserLogged()
  }, [])

  useEffect(() => {
    if (selectedAppointment?.status === 'finished' || (selectedAppointment?.status === 'reopened' && enabled)) {
      if (selectedAppointment?.appointment_payments) {
        setPaymentMethod(
          selectedAppointment?.appointment_payments.map((i) => {
            return {
              payment_value: i.payment_value,
              ...i.payment_method_choosed,
            }
          })
        )
      } else if (selectedAppointment?.formatted_payments) {
        setPaymentMethod(selectedAppointment?.formatted_payments)
      } else {
        setPaymentMethod([
          {
            ...selectedAppointment?.payment_method_choosed,
            id: selectedAppointment?.payment_method_choosed?.payment_method_id,
          },
        ])
      }
      return
    }
    if (
      selectedAppointment?.type !== 'normal' &&
      selectedAppointment?.type !== 'fit' &&
      selectedAppointment?.type !== 'servicePackage' &&
      (paymentMethod?.length === 0 || !paymentMethod[0]?.id)
    ) {
      setPaymentMethod([
        {
          ...selectedAppointment?.formatted_payments,
          id: selectedAppointment?.formatted_payments?.payment_method_id,
        },
      ])
    } else if (paymentMethod?.length === 0 || !paymentMethod?.[0]?.id) {
      setPaymentMethod([])
    }
    setBalanceClient({
      credit: selectedAppointment?.client_balance_description === 'credit' ? true : false,
      debit: selectedAppointment?.client_balance_description === 'debit' ? true : false,
      to_charge: selectedAppointment?.client_balance_description === 'to_charge' ? true : false,
    })
    setCredit(selectedAppointment?.client_balance?.toString()?.replace('-', ''))
  }, [selectedAppointment, selectedAppointment?.status, selectedAppointment?.appointment_payments])
  const showOptions = useMemo(() => {
    if (selectedAppointment?.status === 'client_missed' || selectedAppointment?.status === 'finished') {
      return false
    }
    if (selectedAppointment?.repeat_every_week_day) {
      return false
    }
    if (
      (selectedAppointment?.type === 'signature' || selectedAppointment?.type === 'servicePackage') &&
      total_value === 0
    ) {
      return false
    }
    if (selectedAppointment?.status === 'client_missed' || selectedAppointment?.canceled_at) {
      return false
    }
    return true
  }, [selectedAppointment, total_value])

  const isDeleted = useMemo(() => {
    if (
      selectedAppointment?.status === 'finished' ||
      selectedAppointment?.status === 'client_missed' ||
      selectedAppointment?.canceled_at
    ) {
      return false
    }
    if (selectedAppointment?.status === 'reopened') {
      return true
    }
    if (
      selectedAppointment?.status !== 'finished' &&
      (selectedAppointment?.type === 'signature' || selectedAppointment?.type === 'servicePackage') &&
      (serviceArray?.length > 1 || services?.length > 1)
    ) {
      return true
    }

    if (
      selectedAppointment?.type !== 'signature' &&
      selectedAppointment?.type !== 'servicePackage' &&
      (serviceArray?.length > 1 || services?.length > 1)
    ) {
      return true
    }
    return false
  }, [serviceArray, selectedAppointment])

  const onDeleteService = (item) => {
    if (selectedAppointment?.status === 'reopened') {
      setServiceArray(serviceArray?.filter((i) => i?.appointment_service_id !== item?.appointment_service_id))
      return
    }

    if (
      selectedAppointment?.status !== 'finished' &&
      (selectedAppointment?.type === 'signature' || selectedAppointment?.type === 'servicePackage') &&
      (serviceArray?.length > 1 || services?.length > 1)
    ) {
      // setEnabledServices(false)
      const newArr = selectedAppointment?.formatted_services?.filter(
        (i) => (i?.appointment_service_id || i?.id) !== (item?.appointment_service_id || item?.id)
      )
      if (selectedAppointment?.type === 'signature' && !newArr.some((a) => a.type === 'signature')) {
        toast({
          title: 'Você não pode remover todos os serviços de assinatura da comanda',
          status: 'error',
          duration: 4000,
        })
        return
      }
      if (
        selectedAppointment?.type === 'servicePackage' &&
        !newArr.some((a) => a.type === 'individual_service_package')
      ) {
        toast({
          title: 'Você não pode remover todos os serviços de pacote da comanda',
          status: 'error',
          duration: 4000,
        })
        return
      }
      remove_service_mutation.mutate({
        id: item?.appointment_service_id || item?.id,
      })
      return
      // setSelectedAppointment({ ...selectedAppointment, formatted_services: newArr })
    }

    if (
      selectedAppointment?.type !== 'signature' &&
      selectedAppointment?.type !== 'servicePackage' &&
      (serviceArray?.length > 1 || services?.length > 1)
    ) {
      remove_service_mutation.mutate({
        id: item?.appointment_service_id || item?.id,
      })
      return
    }
    return null
  }

  const isDisabledNfProduct = useMemo(() => {
    if (!paymentMethod?.length) {
      return true
    }
    if (!additionalProductsAppointmentList?.length && !productsList?.length) {
      return true
    }
    if (
      additionalProductsAppointmentList?.reduce?.((acc, val) => acc + Number(val.item_value), 0) === 0 &&
      productsList?.reduce?.((acc, val) => acc + val.value, 0) === 0
    ) {
      return true
    }

    return false
  })

  const isDisabledNfService = useMemo(() => {
    if (!paymentMethod?.length) {
      return true
    }
    if (!servicesList?.length) {
      return true
    }
    if (servicesList?.reduce?.((acc, val) => acc + val.service_value, 0) === 0) {
      return true
    }

    return false
  })

  const showAlertModal = useMemo(() => {
    if (selectedAppointment?.status === 'reopened') {
      return false
    }
    if (paymentMethod?.some?.((i) => i?.name === 'Pagamento pelo app') && selectedAppointment?.type === 'signature') {
      if (paymentMethod?.length === 1 && total_value > 0) {
        return true
      }

      if (paymentMethod?.length > 1 && total_value > 0) {
        const paymentFromAppId = paymentMethod?.find((a) => a?.name === 'Pagamento pelo app')?.id

        const value = inputValue?.find((p) => p?.id === paymentFromAppId)

        if (value?.value !== 0) {
          return true
        }
      }
      return false
    }
  }, [paymentMethod, total_value, selectedAppointment, inputValue])

  if (isLoading || !selectedAppointment) {
    return (
      <Center>
        <Loader />
      </Center>
    )
  }

  return (
    <>
      <Flex
        flexDirection="column"
        width="full"
        height="fit-content"
        pt={theme.pxToRem(8)}
        pb={theme.pxToRem(!isDesktop ? 72 : 40)}
      >
        {/* Header*/}
        <Flex flexDir="column" alignItems="flex-start" justifyContent="flex-start">
          {/*  status */}
          <Flex w="100%" mt={theme.pxToRem(-20)} pt={theme.pxToRem(10)} align="center">
            <Text kind="semiBold" fontSize={16}>
              {t('Status do Agendamento:') + ' '}
            </Text>
            <Text color="transparent">o</Text>
            <Flex
              bg={
                selectedAppointment?.status === 'finished' || selectedAppointment?.status === 'reopened'
                  ? 'transparent'
                  : 'component'
              }
              borderRadius={12}
              pl={theme.pxToRem(10)}
            >
              <Select
                h={theme.pxToRem(36)}
                onChange={(e) => {
                  const selected_status = statusOptions?.find((f) => f?.text === e.target.value)
                  setSelectedAppointment({
                    ...selectedAppointment,
                    status: selected_status?.value,
                  })
                  mudate_updateAppointmentStatus.mutate(selected_status?.value)
                }}
                value={
                  selectedAppointment?.canceled_at ? 'Cancelado' : handleGetStatus(selectedAppointment?.status)?.text
                }
                maxW={theme.pxToRem(180)}
                fontSize={theme.pxToRem(18)}
                iconColor={
                  selectedAppointment?.status === 'finished' || selectedAppointment?.status === 'reopened'
                    ? 'component'
                    : 'primary'
                }
                borderColor="transparent"
                errorBorderColor="baseRed"
                focusBorderColor="transparent"
                variant="unstyled"
                fontFamily="Montserrat Bold"
                textColor={
                  selectedAppointment?.canceled_at ? 'textMedium' : handleGetStatus(selectedAppointment?.status)?.color
                }
                disabled={
                  selectedAppointment?.status === 'finished' ||
                  selectedAppointment?.status === 'reopened' ||
                  selectedAppointment?.canceled_at
                }
              >
                {(selectedAppointment?.repeat_every_week_day
                  ? recurrentOptions
                  : statusOptions.slice(
                      selectedAppointment?.status === 'finished' || selectedAppointment?.status === 'reopened' ? 0 : 2
                    )
                )?.map((item) => (
                  <option
                    style={{
                      backgroundColor: theme.colors.menuBackground,
                      fontFamily: 'Montserrat Medium',
                    }}
                    key={`select_${item?.text}_${item?.id}`}
                  >
                    {item?.text}
                  </option>
                ))}
              </Select>
            </Flex>
          </Flex>
          {/*  status e número da comanda */}
          <Flex flexDir={isDesktop ? 'row' : 'column'} alignItems="center">
            <Text kind="medium" fontSize={14}>
              {`Nº da Comanda: ${
                selectedAppointment?.repeat_every_week_day ? 'Agendamento recorrente' : selectedAppointment?.id
              }`}
            </Text>
            <Flex>
              <Icon name="CalendarCheck" size={20} ml={theme.pxToRem(12)} />
              <Text kind="medium" fontSize={14} ml={theme.pxToRem(4)}>
                {!selectedAppointment?.source ? t('SOURCE_FORM') : name(selectedAppointment?.source)}
              </Text>
            </Flex>
          </Flex>
          <Flex mt="6px" flexDir={isDesktop ? 'row' : 'column'} alignItems="center">
            <Text kind="medium" fontSize={14}>
              {`Criado por: ${selectedAppointment?.created_by?.email} - ${t(selectedAppointment?.created_by?.type)}`}
            </Text>
          </Flex>
          <Flex mt="6px" flexDir={isDesktop ? 'row' : 'column'} alignItems="center">
            <Text kind="medium" fontSize={14}>
              {`Data de criação: ${format(parseISO(selectedAppointment?.createdAt), 'dd/MM/yyyy HH:mm:ss')}`}
            </Text>
          </Flex>
          {selectedAppointment?.canceled_by && (
            <Flex mt="6px" flexDir={isDesktop ? 'row' : 'column'} alignItems="center">
              <Text kind="medium" fontSize={14}>
                {`Cancelado por: ${selectedAppointment?.canceled_by?.email} - ${t(
                  selectedAppointment?.canceled_by?.type
                )}`}
              </Text>
            </Flex>
          )}
          {selectedAppointment?.personalized_id && (
            <Text kind="medium" fontSize={14}>
              {`Nº personalizado da Comanda: ${selectedAppointment?.personalized_id}`}
            </Text>
          )}
          {/* <Text {...subtitle_props}>{t('Data e hora')}</Text> */}
          {/* data e hora e barbeiro */}
          <Flex flexDir={isDesktop ? 'row' : 'column'} w="100%" justify={'space-between'}>
            <Box w={isDesktop ? '49%' : '100%'}>
              <Text {...subtitle_props}>{t('Data e hora')}</Text>
              <DateTimeCard
                date={
                  selectedAppointment?.repeat_every_week_day || !selectedAppointment?.simple_date
                    ? null
                    : parseISO(selectedAppointment?.simple_date)
                }
                time={selectedAppointment?.start_hour}
                finishHour={selectedAppointment?.finish_hour}
                recurrent={selectedAppointment?.repeat_every_week_day}
              />
            </Box>

            <Box w={isDesktop ? '49%' : '100%'}>
              <Text {...subtitle_props}>{t('PROFESSIONAL')}</Text>
              <BarberCard fullWidth barber={selectedAppointment?.barber} />
            </Box>
          </Flex>
          {/* cliente e observações */}
          <Flex flexDir={isDesktop ? 'row' : 'column'} w="100%" justify={'space-between'}>
            {/* cliente */}
            <Box w={isDesktop ? '49%' : '100%'}>
              <Text {...subtitle_props}>{t('CUSTOMER')}</Text>
              <ClientCard size="md" onPress={() => onClickClient()} client={selectedAppointment?.client} isDetails />
            </Box>
            {/* observações */}
            <Box w={isDesktop ? '49%' : '100%'}>
              <Text {...subtitle_props}>{t('Última observação')}</Text>
              <ObservationCard
                inModal
                item={
                  selectedAppointment?.client?.client_observations?.length > 0
                    ? selectedAppointment?.client?.client_observations?.[0]
                    : { observation_text: 'Sem observações' }
                }
                type="details"
                mb={theme.pxToRem(12)}
                onDelete={() => {
                  if (clients === 'edit') {
                    setObservationsModalVisible(true)
                    setSelectedCustomer(selectedAppointment?.client)
                  } else {
                    toast({
                      title: t('PERMISSION_DENIED'),
                      status: 'error',
                      duration: 4000,
                      isClosable: true,
                    })
                  }
                }}
              />
            </Box>
          </Flex>
        </Flex>
        {/* serviços */}
        <Box mt={-2}>
          {services?.length > 0 && <Text {...subtitle_props}>{t('_SERVICE')}</Text>}
          {(selectedAppointment?.status === 'reopened' ? serviceArray : services)?.map((item, index) => (
            <ServiceCard
              key={index}
              index={index}
              marginBottom={theme.pxToRem(12)}
              fullWidth
              setEnabled={setEnabled}
              isDetails={true}
              service={item}
              date={selectedAppointment?.date}
              recurrent={selectedAppointment?.repeat_every_week_day}
              reopened={
                selectedAppointment?.status !== 'finished' &&
                selectedAppointment?.status !== 'client_missed' &&
                !selectedAppointment?.canceled_at
              }
              onDelete={isDeleted ? () => onDeleteService(item) : false}
            />
          ))}
          {selectedAppointment?.status !== 'finished' &&
            selectedAppointment?.status !== 'client_missed' &&
            !selectedAppointment?.canceled_at &&
            !selectedAppointment?.repeat_every_week_day && (
              <Button
                mb="12px"
                size="block"
                kind="appointmentOptions"
                onClick={() => {
                  if (appointment === 'edit') {
                    setStep(APPOINTMENT_DETAILS_STEPS.service)
                  } else {
                    toast({
                      title: t('PERMISSION_DENIED'),
                      status: 'error',
                      duration: 4000,
                      isClosable: true,
                    })
                  }
                }}
              >
                {t('ADD_SERVICE_BUTTON')}
              </Button>
            )}
        </Box>
        {/* produtos */}
        {selectedAppointment?.status !== 'client_missed' &&
          !selectedAppointment?.canceled_at &&
          !selectedAppointment?.repeat_every_week_day && (
            <Box w="100%">
              <Text {...subtitle_props} mt="4px">
                {t('ADDITIONAL_PRODUCTS')}
              </Text>

              {(selectedAppointment?.status === 'reopened'
                ? productsList
                : additionalProductsAppointmentList?.length > 0
                ? additionalProductsAppointmentList
                : []
              )?.map((item, index) => {
                return (
                  <AdditionalProductCard
                    br={8}
                    key={`additional_${index}`}
                    reopened={selectedAppointment?.status === 'awaiting' || selectedAppointment?.status === 'pending'}
                    product={{
                      ...item,
                      price: item?.item_value || item?.product_value || item?.additional_product?.price || item?.price,
                    }}
                    index={index}
                    onClick={selectedAppointment?.status !== 'finished' ? () => onRemoveProduct(item) : null}
                  />
                )
              })}

              {isBlocked ? (
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  px={theme.pxToRem(16)}
                  py={theme.pxToRem(20)}
                  width="100%"
                  bg="cardBackground"
                  borderWidth={theme.pxToRem(1.8)}
                  borderColor="modalBorder"
                  borderRadius={8}
                >
                  <Text kind="bold" fontSize={14} color="primary">
                    {t('PREMIUM_EXCLUSIVE')}
                  </Text>

                  <Icon name="PremiumCrown" />
                </Flex>
              ) : (
                <>
                  {selectedAppointment?.status !== 'client_missed' &&
                    !selectedAppointment?.canceled_at &&
                    selectedAppointment?.status !== 'finished' && (
                      <Flex flexDir="row">
                        <Button
                          size="block"
                          kind="appointmentOptions"
                          mt={theme.pxToRem(additionalProductsAppointmentList?.length > 0 ? 12 : 0)}
                          onClick={() => {
                            if (appointment === 'edit') {
                              setStep(APPOINTMENT_DETAILS_STEPS.addProducts)
                            } else {
                              toast({
                                title: t('PERMISSION_DENIED'),
                                status: 'error',
                                duration: 4000,
                                isClosable: true,
                              })
                            }
                          }}
                        >
                          {t('ADD_PRODUCTS')}
                        </Button>
                      </Flex>
                    )}

                  {selectedAppointment?.status === 'finished' && additionalProductsAppointmentList?.length === 0 && (
                    <SourceCard fullWidth source={'NOT_INFORMED'} text={'NO_PRODUCT'} />
                  )}
                </>
              )}
            </Box>
          )}
        {/* resumo da comanda */}
        {selectedAppointment?.status !== 'client_missed' && !selectedAppointment?.canceled_at && (
          <Text {...subtitle_props} mt="16px">
            {t('Resumo da Comanda')}
          </Text>
        )}
        {selectedAppointment?.status !== 'client_missed' && !selectedAppointment?.canceled_at && (
          <Box flex={selectedAppointment?.status !== 'client_missed' && !selectedAppointment?.canceled_at ? 0 : 1}>
            <Flex flexDir="column" borderColor="border" borderTopWidth={1}>
              {(selectedAppointment?.status === 'reopened' ? serviceArray : services)?.map((item, index) => (
                <Flex
                  mt={theme.pxToRem(index === 0 && 12)}
                  w="full"
                  flexDir="row"
                  alignItems="center"
                  justifyContent="space-between"
                  key={index}
                >
                  <Text fontSize={theme.pxToRem(14)} kind="semiBold">
                    {item?.product?.name || item?.name}
                  </Text>
                  <Text fontSize={theme.pxToRem(14)} kind="semiBold">
                    {item?.type === 'signature' ||
                    item?.type === 'individual_service_package' ||
                    item?.service?.type === 'signature'
                      ? 'Serviço pelo clube'
                      : formatPrice(item?.product?.price || item?.price || item?.item_value)}
                  </Text>
                </Flex>
              ))}
              {(selectedAppointment?.status === 'reopened'
                ? productsList
                : additionalProductsAppointmentList?.length > 0
                ? additionalProductsAppointmentList
                : []
              )?.map((item, index) => (
                <Flex
                  key={`additiona_value_${index}`}
                  mt={theme.pxToRem(8)}
                  w="full"
                  flexDir="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Text fontSize={theme.pxToRem(14)} kind="medium">
                    {item?.additional_product?.name || item?.name}
                  </Text>
                  <Text fontSize={theme.pxToRem(14)} kind="semiBold">
                    {formatPrice(
                      item?.product_value || item?.additional_product?.price || item?.item_value || item?.price
                    )}
                  </Text>
                </Flex>
              ))}
              {((selectedAppointment?.client_balance_description === 'to_charge' &&
                selectedAppointment?.status !== 'reopened') ||
                !Object.values(balanceClient)?.every((i) => !i) ||
                credit?.length > 0) && (
                <Flex w="full" flexDir="row" alignItems="center" justifyContent="space-between" mb="12px">
                  <Text fontSize={theme.pxToRem(14)} kind="semiBold">
                    {t('CLIENT_BALANCE')}
                  </Text>
                  <Text fontSize={theme.pxToRem(14)} kind="semiBold">
                    {formatPrice(
                      balanceClient?.debit ? Number(-credit) : selectedAppointment?.client_balance || credit
                    )}
                  </Text>
                </Flex>
              )}
              <Flex
                mt={theme.pxToRem(8)}
                w="full"
                flexDir="row"
                alignItems="center"
                justifyContent="space-between"
                mb="12px"
              >
                <Flex align="center">
                  {(selectedAppointment?.discount || discount) &&
                  (selectedAppointment?.discount > 0 || discount_value > 0) ? (
                    <>
                      {normal ? (
                        <>
                          {selectedAppointment?.status !== 'finished' && (
                            <Icon
                              cursor="pointer"
                              onClick={
                                selectedAppointment?.status !== 'finished' && appointment === 'edit'
                                  ? () => {
                                      setNormal(true)
                                      setDiscount(null)
                                      setSelectedAppointment({
                                        ...selectedAppointment,
                                        discount: null,
                                      })
                                    }
                                  : null
                              }
                              mr="8px"
                              name="Trash"
                              size={16}
                              color="danger"
                            />
                          )}
                          <Text fontSize={theme.pxToRem(14)} kind="medium">
                            {`Desconto: ${
                              normal
                                ? selectedAppointment?.status === 'reopened'
                                  ? discount?.discount_observation || ''
                                  : selectedAppointment?.discount_observation || discount?.discount_observation || ''
                                : ''
                            }`}
                          </Text>
                        </>
                      ) : (
                        <Box>
                          {/* {discount
                            ?.filter((d) => d?.discount_value)
                            ?.map((i) => (
                              <Flex flexDir="column" mb="12px">
                                <Text fontSize={theme.pxToRem(14)} kind="semiBold">
                                  {`Desconto: ${i?.name}`}
                                </Text>
                              </Flex>
                            ))} */}
                          <Flex>
                            {selectedAppointment?.status !== 'finished' && (
                              <Icon
                                cursor="pointer"
                                onClick={
                                  selectedAppointment?.status !== 'finished' && appointment === 'edit'
                                    ? () => {
                                        setNormal(true)
                                        setDiscount(null)
                                      }
                                    : null
                                }
                                mr="8px"
                                name="Trash"
                                size={16}
                                color="danger"
                              />
                            )}
                            <Text fontSize={theme.pxToRem(14)} kind="medium">
                              Desconto total oferecido:
                            </Text>
                          </Flex>
                        </Box>
                      )}
                    </>
                  ) : (
                    <>
                      {showOptions && (
                        <Text fontSize={theme.pxToRem(16)} kind="semiBold">
                          Desconto
                        </Text>
                      )}
                    </>
                  )}
                </Flex>
                {(selectedAppointment?.discount || discount) &&
                (selectedAppointment?.discount > 0 || discount_value > 0) ? (
                  <>
                    <Text color="error" fontSize={theme.pxToRem(14)} kind="semiBold">
                      -{' '}
                      {formatPrice(
                        selectedAppointment?.status === 'reopened'
                          ? discount_value
                          : selectedAppointment?.discount || discount_value
                      )}
                    </Text>
                  </>
                ) : (
                  <>
                    {showOptions && (
                      <Link
                        onClick={() => {
                          if (appointment === 'edit') {
                            setStep(APPOINTMENT_DETAILS_STEPS.discount)
                          } else {
                            toast({
                              title: t('PERMISSION_DENIED'),
                              status: 'error',
                              duration: 4000,
                              isClosable: true,
                            })
                          }
                        }}
                      >
                        Adicionar desconto
                      </Link>
                    )}
                  </>
                )}
              </Flex>
              {invalidDiscount && (
                <Text mb="6px" color="error">
                  Erro: Desconto maior do que o valor total
                </Text>
              )}
            </Flex>
            <Flex
              flexDir="row"
              w="full"
              alignItems="center"
              justify="space-between"
              borderColor="border"
              borderBottomWidth={1}
              borderTopWidth={1}
              paddingY={theme.pxToRem(12)}
            >
              <Text kind="extraBold" fontSize={18} color="white">
                {t('Total da comanda')}
              </Text>
              <Text kind="extraBold" fontSize={18} color="white">
                {formatPrice(sub_total_value)}
              </Text>
            </Flex>
            {isBlocked && selectedAppointment?.status === 'finished' ? (
              <Box pt={theme.pxToRem(12)} width="100%" position="relative" height={220}>
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  mb={theme.pxToRem(12)}
                  px={theme.pxToRem(-24)}
                  w="full"
                >
                  <Text fontSize={theme.pxToRem(14)} kind="semiBold">
                    {t('PERCENTAGE_PAY')} 3%
                  </Text>
                  <Text fontSize={theme.pxToRem(14)} kind="semiBold">
                    - {t('CURRENCY')} X,XX
                  </Text>
                </Flex>

                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  mb={theme.pxToRem(12)}
                  mt={theme.pxToRem(12)}
                  w="full"
                >
                  <Text fontSize={theme.pxToRem(14)} kind="semiBold">
                    {t('PERCENTAGE_SERV')} 3%
                  </Text>
                  <Text fontSize={theme.pxToRem(14)} kind="semiBold">
                    - {t('CURRENCY')} X,XX
                  </Text>
                </Flex>

                <Flex
                  flexDir="row"
                  w="full"
                  alignItems="center"
                  justify="space-between"
                  borderColor="border"
                  borderBottomWidth={1}
                  borderTopWidth={1}
                  paddingY={theme.pxToRem(12)}
                  mb={theme.pxToRem(16)}
                >
                  <Text kind="extraBold" fontSize={18} color="white">
                    Total
                  </Text>
                  <Text kind="extraBold" fontSize={18} color="white">
                    {t('CURRENCY')} XX,XX
                  </Text>
                </Flex>

                <Flex
                  flexDir="column"
                  alignItems="center"
                  justifyContent="center"
                  width="100%"
                  height="100%"
                  position="absolute"
                  top={0}
                  bg="rgba(17, 17, 17, 0.7)"
                >
                  <Icon name="PremiumCrown" />
                  <Text
                    kind="bold"
                    fontSize={14}
                    color="primary"
                    align="center"
                    mt={theme.pxToRem(8)}
                    width="100%"
                    maxWidth={250}
                  >
                    Detalhamento financeiro disponível apenas para assinantes Premium
                  </Text>
                </Flex>
              </Box>
            ) : (
              <>
                <Flex mt={2} mb={2} flexDir="column" borderColor="border">
                  {selectedAppointment?.status === 'finished' &&
                    calculated_info(selectedAppointment)?.map((info) => (
                      <Flex flexDir="column" mt={info?.mt}>
                        {info?.data?.map((i) => (
                          <Flex w="100%" justify={'space-between'}>
                            <Text color="textMedium" fontSize={13}>
                              {i?.title}
                            </Text>
                            {/* <Text fontSize={14}>{i?.have_discount}</Text> */}
                            <Text
                              color={i?.value < 0 ? 'danger' : i?.value == 0 ? 'textMedium' : 'textLight'}
                              fontSize={13}
                              kind={'medium'}
                            >
                              {formatPrice(i?.value)}
                            </Text>
                          </Flex>
                        ))}
                      </Flex>
                    ))}
                </Flex>

                {selectedAppointment?.status === 'finished' && (
                  <Flex
                    flexDir="row"
                    w="full"
                    alignItems="center"
                    justify="space-between"
                    borderColor="border"
                    borderBottomWidth={1}
                    borderTopWidth={1}
                    paddingY={theme.pxToRem(12)}
                    mb={theme.pxToRem(16)}
                  >
                    <Text kind="semiBold" fontSize={15} color="textLight">
                      Total final (descontando taxas e comissões)
                    </Text>
                    <Text kind="bold" fontSize={16} color="white">
                      {formatPrice(total_value)}
                    </Text>
                  </Flex>
                )}
              </>
            )}
          </Box>
        )}
        {/* data de pagamento */}
        {selectedAppointment?.status !== 'client_missed' &&
          !selectedAppointment?.canceled_at &&
          selectedAppointment?.payment_date && (
            <Text kind="semiBold" fontSize={14} color="textGrey">
              {paymentDate(selectedAppointment?.payment_date?.substring(0, 10))}
            </Text>
          )}
        {/* metodos de pagamento */}
        {((selectedAppointment?.status !== 'client_missed' &&
          !selectedAppointment?.canceled_at &&
          !selectedAppointment?.repeat_every_week_day) ||
          (selectedAppointment?.type === 'servicePackage' && total_value > 0)) && (
          <Box>
            <Text {...subtitle_props}>{t('PAYMENT_METHODS')}</Text>
            <Flex flexDir="column">
              <Box>
                {!!paymentMethod?.length &&
                  paymentMethod?.map((pm, index) => (
                    <Box
                      key={index}
                      minHeight={
                        paymentMethod?.length > 1 && selectedAppointment?.status !== 'finished' && theme.pxToRem(156)
                      }
                      mb={theme.pxToRem(16)}
                    >
                      <PaymentMethodCard
                        reopened={selectedAppointment?.status === 'reopened'}
                        marginBottom={theme.pxToRem(16)}
                        status={selectedAppointment?.status}
                        paymentMethod={pm}
                        onClick={
                          selectedAppointment?.status !== 'finished' &&
                          appointment === 'edit' &&
                          pm?.name !== 'Pagamento pelo app'
                            ? () => setPaymentMethod(paymentMethod.filter((r) => r !== pm))
                            : null
                        }
                      />
                      {paymentMethod?.length > 1 && selectedAppointment?.status !== 'finished' && (
                        // Fazer a verificação valor digitado é igual total_value
                        <TextInput
                          mask="CURRENCY_MASK"
                          value={inputValue?.find((i) => i?.id === pm?.id)?.value}
                          label={t('VALUE_PAYMENT')}
                          placeholder="Digite o valor"
                          mb={theme.pxToRem(16)}
                          onChange={(e) => handleChange(pm?.payment_method_id || pm?.id, e.target.value)}
                        />
                      )}
                    </Box>
                  ))}

                {((selectedAppointment?.status !== 'client_missed' &&
                  !selectedAppointment?.canceled_at &&
                  selectedAppointment?.status !== 'finished' &&
                  selectedAppointment?.type !== 'signature') ||
                  (selectedAppointment?.type === 'signature' &&
                    total_value > 0 &&
                    selectedAppointment?.status !== 'client_missed' &&
                    !selectedAppointment?.canceled_at &&
                    selectedAppointment?.status !== 'finished') ||
                  selectedAppointment?.status === 'reopened') && (
                  <Button
                    marginBottom={theme.pxToRem(4)}
                    size="block"
                    kind="appointmentOptions"
                    onClick={() => {
                      if (appointment === 'edit') {
                        setStep(APPOINTMENT_DETAILS_STEPS.selectPaymentMethod)
                      } else {
                        toast({
                          title: t('PERMISSION_DENIED'),
                          status: 'error',
                          duration: 4000,
                          isClosable: true,
                        })
                      }
                    }}
                  >
                    {t('PAYMENT_METHODS')}
                  </Button>
                )}

                {paymentMethod?.length > 1 && totalValue === 0 && selectedAppointment?.status !== 'finished' ? (
                  <Text color="red"> Digite o valor igual ao total: {formatPrice(total_value - totalValue)}</Text>
                ) : (
                  <Text color="red">
                    {paymentMethod?.length > 1 &&
                      totalValue !== total_value &&
                      selectedAppointment?.status !== 'finished' && (
                        <>
                          O valor inserido é {totalValue > total_value ? 'maior' : 'menor'} que o total em{' '}
                          {formatPrice(Math.abs(totalValue - total_value))}
                        </>
                      )}
                  </Text>
                )}
              </Box>
            </Flex>
          </Box>
        )}
        {/* saldo do cliente */}
        {selectedAppointment?.status !== 'client_missed' &&
          !selectedAppointment?.canceled_at &&
          !selectedAppointment?.repeat_every_week_day && (
            <Box>
              <Text {...subtitle_props}>
                {`${t('CLIENT_BALANCE')}: ${formatCurrency(
                  selectedAppointment?.client?.barbershop_client?.[0]?.client_balance || 0
                )}`}
              </Text>
              {selectedAppointment?.status !== 'finished' && (
                <Flex flexDir="column">
                  <>
                    {isBlocked ? (
                      <Flex
                        alignItems="center"
                        justifyContent="space-between"
                        px={theme.pxToRem(16)}
                        py={theme.pxToRem(20)}
                        width="100%"
                        bg="cardBackground"
                        borderWidth={theme.pxToRem(1.8)}
                        borderColor="modalBorder"
                        borderRadius={8}
                      >
                        <Text kind="bold" fontSize={14} color="primary">
                          {t('PREMIUM_EXCLUSIVE')}
                        </Text>

                        <Icon name="PremiumCrown" />
                      </Flex>
                    ) : (
                      <>
                        <SelectCard
                          item={{
                            title:
                              selectedAppointment?.client?.barbershop_client?.[0]?.client_balance &&
                              selectedAppointment?.client?.barbershop_client?.[0]?.client_balance < 0
                                ? t('USE_DEBIT')
                                : t('CREDIT_CLIENT_BALANCE'),
                            // description: t('CREDIT_CLIENT_BALANCE_DESC'),
                          }}
                          setSelected={(e) => handleSelectCredit('credit', e)}
                          selected={balanceClient?.credit}
                          disabled={
                            selectedAppointment?.status === 'finished' ||
                            (!balanceClient?.credit && !Object.values(balanceClient)?.every((i) => !i))
                          }
                          mb={!balanceClient?.credit ? '16px' : '8px'}
                        />
                        {balanceClient?.credit && (
                          <Flex flexDir="column" mb="16px">
                            <TextInput
                              value={credit}
                              label={t('VALUE')}
                              placeholder="Digite o valor"
                              type="number"
                              onChange={(e) => setCredit(e.target.value.replace('-', ''))}
                              leftElement={<Text kind="bold">R$</Text>}
                              onWheel={(e) => e.target.blur()}
                              disabled={selectedAppointment?.status === 'finished'}
                            />
                            <Text kind="medium" fontSize={14} color="textGrey" mt="48px">
                              {`${t('CREDIT_DESC_')} ${formatPrice(
                                (selectedAppointment?.client?.barbershop_client?.[0]?.client_balance || 0) +
                                  Number(credit)
                              )}`}
                            </Text>
                          </Flex>
                        )}
                        <SelectCard
                          item={{
                            title:
                              selectedAppointment?.client?.barbershop_client?.[0]?.client_balance &&
                              selectedAppointment?.client?.barbershop_client?.[0]?.client_balance > 0
                                ? t('USE_CREDIT')
                                : t('DEBIT_CLIENT_BALANCE'),
                            // description: t('DEBIT_CLIENT_BALANCE_DESC'),
                          }}
                          setSelected={(e) => handleSelectCredit('debit', e)}
                          selected={balanceClient?.debit}
                          disabled={
                            selectedAppointment?.status === 'finished' ||
                            (!balanceClient?.debit && !Object.values(balanceClient)?.every((i) => !i))
                          }
                          mb={'8px'}
                        />
                        {balanceClient?.debit && (
                          <Flex flexDir="column" mb="16px">
                            <TextInput
                              value={credit}
                              label={t('VALUE')}
                              placeholder="Digite o valor"
                              type="number"
                              onChange={(e) => setCredit(e.target.value.replace('-', ''))}
                              leftElement={<Text kind="bold">R$</Text>}
                              onWheel={(e) => e.target.blur()}
                              disabled={selectedAppointment?.status === 'finished'}
                            />
                            <Text kind="medium" fontSize={14} mt="48px" color="textGrey">
                              {`${t('CREDIT_DESC_')} ${formatPrice(
                                (selectedAppointment?.client?.barbershop_client?.[0]?.client_balance || 0) -
                                  Number(credit)
                              )}`}
                            </Text>
                          </Flex>
                        )}
                      </>
                    )}
                  </>
                </Flex>
              )}
            </Box>
          )}
        {/* fonte do Agendamento */}
        {selectedAppointment?.status !== 'client_missed' &&
          !selectedAppointment?.canceled_at &&
          selectedAppointment?.status !== 'finished' &&
          !selectedAppointment?.repeat_every_week_day && (
            <Box mb="12px">
              <Text {...subtitle_props}>{t('SELECT_SOURCE')}</Text>

              {selectedAppointment?.status !== 'finished' &&
                !selectedAppointment?.source &&
                !source &&
                appointment === 'edit' && (
                  <Button
                    size="block"
                    kind="appointmentOptions"
                    onClick={() => setStep(APPOINTMENT_DETAILS_STEPS.addSource)}
                  >
                    {t('SELECT_SOURCE')}
                  </Button>
                )}
              {selectedAppointment?.status !== 'finished' && (selectedAppointment?.source || source) && (
                <SourceCard
                  fullWidth
                  source={selectedAppointment?.source || source}
                  onClick={
                    selectedAppointment?.status !== 'finished' &&
                    selectedAppointment?.status !== 'client_missed' &&
                    !selectedAppointment?.canceled_at
                      ? () => {
                          setSource(null)
                          setSelectedAppointment({
                            ...selectedAppointment,
                            source: null,
                          })
                        }
                      : null
                  }
                />
              )}
            </Box>
          )}
        <ModalAlert
          isOpen={cancelModalOpen}
          onClose={() => setCancelModalOpen(false)}
          onConfirm={() => mutateDeleteAppointment.mutate()}
          primaryButtonLabel="Sim"
          modalTitle={t('EXCLUDE_APPOINTMENT')}
        >
          <Text fontWeight="bold" m={`${theme.pxToRem(16)} 0`}>
            {t('EXCLUDE_APPOINTMENT_CUSTOMER')}
            {selectedAppointment?.client?.name}" ?
          </Text>
        </ModalAlert>
        <ModalAlert
          isOpen={alertModalOpen}
          onClose={() => setAlertModalOpen(false)}
          onConfirm={onClickFinish}
          primaryButtonLabel="Finalizar"
          modalTitle="Finalizar comanda"
        >
          <Text fontWeight="bold" m={`${theme.pxToRem(16)} 0`}>
            {`Será cobrado o valor de ${formatPrice(
              paymentMethod?.length === 1 && selectedAppointment?.type === 'signature'
                ? total_value
                : inputValue?.find((p) => p?.id === paymentMethod?.find((a) => a?.name === 'Pagamento pelo app')?.id)
                    ?.value
            )} no cartão de crédito do cliente atrelado a assinatura`}
          </Text>
        </ModalAlert>
        <AppointmentsModal
          isEditing={isEditing}
          isModalOpen={isEditing}
          selectedAppointment={selectedAppointment}
          goBack={() => setIsEditing(false)}
        />
        <UpdateAppointmentId isOpen={modalIdOpen} onClose={() => setModalIdOpen(false)} />
        {!!selectedCustomer && isCustomerDetailModalOpen && (
          <ClientDetailModal
            isModalOpen={isCustomerDetailModalOpen}
            onClose={() => setCustomerDetailModal(false)}
            customerData={selectedCustomer}
          />
        )}
        {(userData?.barbershop?.nf_module_active || userData?.nf_module_active) &&
          !invoiceQuery.data?.id &&
          !selectedAppointment?.repeat_every_week_day &&
          selectedAppointment?.status !== 'client_missed' &&
          !selectedAppointment?.canceled_at &&
          total_value !== 0 && (
            <IssuerInvoiceForm
              appointmentId={selectedAppointment?.id}
              isDisabledProduct={isDisabledNfProduct}
              isDisabledService={isDisabledNfService}
              onClickIssuerInvoice={handleIssuerInvoice}
              onClickInvoiceCustom={() => setStep(APPOINTMENT_DETAILS_STEPS.editInvoice)}
              mb={8}
              setAppointmentEmission={setAppointmentEmission}
              appointmentEmission={appointmentEmission}
              titleProps={{
                ...subtitle_props,
                textTransform: 'normal',
                mb: -3,
              }}
            />
          )}
      </Flex>
      <Flex
        borderTopColor="grey"
        borderTopWidth="1px"
        ml="-24px"
        position="absolute"
        bottom={0}
        bg="background"
        h="110px"
        w="100%"
        p="20px"
        py="24px"
      >
        {appointment === 'edit' && !selectedAppointment?.canceled_at && (
          <Button
            w={
              selectedAppointment?.status === 'client_missed'
                ? '100%'
                : selectedAppointment?.repeat_every_week_day
                ? '50%'
                : '30%'
            }
            size="block"
            kind="danger"
            icon={selectedAppointment?.repeat_every_week_day ? false : 'Trash'}
            numberOfLines={2}
            iconColor="white"
            onClick={() =>
              selectedAppointment?.repeat_every_week_day ? mutateException.mutate() : setCancelModalOpen(true)
            }
            disabled={mutateDeleteAppointment.isLoading || mutateException.isLoading}
            loading={mutateDeleteAppointment.isLoading || mutateException.isLoading}
          >
            {selectedAppointment?.repeat_every_week_day && selectedDate
              ? `Cancelar apenas dia (${String(format(selectedDate, 'dd/MM'))})`
              : t('DELETE')}
          </Button>
        )}
        {selectedAppointment?.status !== 'finished' &&
          selectedAppointment?.status !== 'client_missed' &&
          !selectedAppointment?.canceled_at &&
          appointment === 'edit' &&
          selectedAppointment?.status !== 'reopened' &&
          !selectedAppointment?.repeat_every_week_day && (
            <Button
              ml="12px"
              w="30%"
              size="block"
              kind="outline-primary"
              onClick={() => {
                setClient(selectedAppointment?.client)
                setType(selectedAppointment?.type)
                setBarber(selectedAppointment?.barber)
                setServiceArray(selectedAppointment?.product || selectedAppointment?.services)
                setDate(parseISO(selectedAppointment?.simple_date))
                setTime(selectedAppointment?.start_hour.substring(0, 5))
                setSource(selectedAppointment?.source)
                setFinishHour(selectedAppointment?.finish_hour)
                setIsEditing(true)
              }}
            >
              {t('EDIT_SERVICE')}
            </Button>
          )}
        {reopen_permission === 'edit' &&
          selectedAppointment?.status === 'finished' &&
          !selectedAppointment?.canceled_at && (
            <Button
              ml="12px"
              size="block"
              kind="primary"
              onClick={() => {
                onReopen()
              }}
              disabled={mutateDeleteAppointment.isLoading}
              loading={mutateDeleteAppointment.isLoading}
            >
              {t('REOPEN_APPOINTMENT')}
            </Button>
          )}
        {selectedAppointment?.status !== 'client_missed' &&
          !selectedAppointment?.canceled_at &&
          selectedAppointment?.status !== 'finished' &&
          appointment === 'edit' && (
            <Button
              w="50%"
              ml="12px"
              size="block"
              kind={selectedAppointment?.repeat_every_week_day ? 'danger' : 'primary'}
              onClick={
                selectedAppointment?.repeat_every_week_day
                  ? mutateDeleteRecurrency.mutate
                  : showAlertModal
                  ? () => setAlertModalOpen(true)
                  : onClickFinish
              }
              disabled={
                selectedAppointment?.repeat_every_week_day
                  ? mutateDeleteRecurrency.isLoading
                  : selectedAppointment?.type === 'servicePackage'
                  ? total_value > 0 && (!paymentMethod || paymentMethod?.length === 0)
                  : isButtonDisabled
              }
              loading={mutateFinished.isLoading || mutateFinishedReopened.isLoading || mutateDeleteRecurrency.isLoading}
            >
              {selectedAppointment?.repeat_every_week_day ? 'Cancelar permanentemente' : t('SUBMIT')}
            </Button>
          )}
        {observationsModalVisible && (
          <ClientObservationsModal
            isModalOpen={observationsModalVisible}
            onClose={() => {
              setObservationsModalVisible(false)
              refetchObservations()
            }}
          />
        )}
      </Flex>
    </>
  )
}
