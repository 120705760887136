import React, { useMemo, useState } from 'react'
import { useTheme, Select, Grid, GridItem, Box } from '@chakra-ui/react'
import { Flex, Text, TextInput, Button, Loader, SelectDateButton, CheckBox } from 'components/atoms'
import { pxToRem } from 'styles/metrics'
import { useBreakpoint, useBarbershop } from 'services/hooks'
import { useBarbershopStore } from 'services/stores'
import { Calendar, FinanceResumeCard, ModalMenu, Table } from 'components/molecules'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { cards, columns, renderInput } from './settings'
import { t } from 'services/translation'
import { parseISO, format } from 'date-fns'

export const ClearedComissionModal = ({ isModalOpen, onClose, refetch, ...props }) => {
  const { userData } = useBarbershopStore()
  const { isDesktop } = useBreakpoint()
  const [value, setValue] = useState()
  const theme = useTheme()
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [paymentDate, setPaymentDate] = useState()
  const [signature, setSignature] = useState(false)
  const [collaborator, setCollaborator] = useState(false)
  const [selectedDay, setSelectedDay] = useState(new Date())
  const {
    getAllEmployees,
    getAppointmentsToCleared,
    getAppointmentsSignatureToCleared,
    paidBarberAppointments,
    paidCollaboratorAppointments,
  } = useBarbershop()
  const queryClient = useQueryClient()

  const mutate = useMutation('create-paid-barber-appointment', (params) => paidBarberAppointments(params))
  const mutateCollaborator = useMutation('create-paid-collaborator-appointment', (params) =>
    paidCollaboratorAppointments(params)
  )

  const onSuccess = () => {
    setStartDate()
    setEndDate()
    onClose()
    setValue()
    if (refetch) {
      refetch()
    }
  }

  const { data } = useQuery('get-all-employees', () => getAllEmployees({ barbershop_id: userData.id }))

  const isDisabled = useMemo(() => {
    return (
      (!signature && (!startDate || startDate?.length === 0)) ||
      (!signature && (!endDate || endDate?.length === 0)) ||
      !value ||
      value?.length === 0 ||
      (signature && !selectedDay)
    )
  }, [value, startDate, endDate, signature])

  const {
    data: appointmentsList,
    isLoading,
    isError,
  } = useQuery(
    ['get-appointments-to-cleared', value],
    () =>
      signature
        ? getAppointmentsSignatureToCleared({
            barber_id: value,
            start_date: format(selectedDay, 'yyyy-MM-dd'),
            end_date: format(selectedDay, 'yyyy-MM-dd'),
            barbershop_id: userData?.barbershop?.id || userData?.id,
          })
        : getAppointmentsToCleared({
            barber_id: collaborator ? null : value,
            employee_id: collaborator ? value : null,
            start_date: startDate,
            end_date: endDate,
            barbershop_id: userData?.barbershop?.id || userData?.id,
            signature,
          }),
    {
      enabled: !isDisabled,
      retry: false,
      refetchOnWindowFocus: false,
    }
  )

  const createPaidBarber = () => {
    const params = {
      barbershop_id: userData?.barbershop?.id || userData?.id,
      start_date: startDate,
      end_date: endDate,
      type: 'paid_appointment',
      appointments_id: appointmentsList?.data?.filter((a) => a?.status === 'finished')?.map((i) => i?.id),
      value: appointmentsList?.total_comission,
      paid_type: signature ? 'signature' : 'normal',
      payment_date: paymentDate,
    }

    if (collaborator) {
      params.employee_id = value
      mutateCollaborator.mutate(
        { params, onSuccess },
        {
          onSettled: () => {
            refetch()
            queryClient.invalidateQueries('get-barber-extract')
          },
        }
      )
      return
    }
    params.barber_id = value
    mutate.mutate(
      { params, onSuccess },
      {
        onSettled: () => {
          refetch()
          queryClient.invalidateQueries('get-barber-extract')
        },
      }
    )
  }
  // const selectCollaborator = (id)
  return (
    <>
      <ModalMenu
        isOpen={isModalOpen}
        onClose={onClose}
        title="Dar baixa em comissões"
        headerStyle={{ mb: pxToRem(-12) }}
        size="xl"
      >
        <Flex position="relative" direction="column" alignItems={!isDesktop && 'center'}>
          {userData?.signature_club_enable && !collaborator && (
            <Box marginBottom={theme.pxToRem(16)}>
              <Text kind="medium" fontSize={theme.pxToRem(14)} color="textGrey" mb={theme.pxToRem(8)}>
                Selecione o tipo de comissão para dar baixa
              </Text>
              <Flex>
                <Button
                  kind={signature ? 'primary' : 'secondary'}
                  size="medium"
                  marginRight="32px"
                  onClick={() => {
                    setSignature(true)
                  }}
                >
                  Assinatura
                </Button>
                <Button
                  kind={!signature ? 'primary' : 'secondary'}
                  size="medium"
                  onClick={() => {
                    setSignature(false)
                  }}
                >
                  Serviços normais
                </Button>
              </Flex>
            </Box>
          )}
          {!signature ? (
            <Flex mt={pxToRem(12)} w="full">
              {renderInput?.map((i) => (
                <TextInput
                  {...i}
                  h="46px"
                  noBorder
                  type="date"
                  name={i?.name}
                  value={i?.name === 'start_date' ? startDate : endDate}
                  onChange={(e) => {
                    if (i?.name === 'start_date') {
                      setStartDate(e.target.value)
                    } else {
                      setEndDate(e.target.value)
                    }
                  }}
                  label={t(i?.label)}
                  padding={theme.pxToRem(10)}
                />
              ))}
            </Flex>
          ) : (
            <SelectDateButton width="100%" h="42px" onlyMonth date={selectedDay} setDate={setSelectedDay} />
          )}
          <Flex mt="32px" mb={theme.pxToRem(12)} flexDir="column">
            <Text kind="medium" fontSize={theme.pxToRem(14)} color="textGrey" mb={theme.pxToRem(8)}>
              {t('SELECT_BARBER')}
            </Text>
            <Flex
              bg="backgroundLayout"
              flexDir="column"
              h={theme.pxToRem(56)}
              align="center"
              justify="center"
              px={theme.pxToRem(16)}
              borderRadius={theme.pxToRem(8)}
              borderWidth={1}
              borderColor="border"
            >
              <Select
                name="barber"
                value={value}
                w="full"
                fontFamily="Montserrat Bold"
                placeholder={t('SELECT')}
                fontSize={14}
                textColor="textLight"
                iconColor="primary"
                borderColor="transparent"
                errorBorderColor="baseRed"
                focusBorderColor="transparent"
                variant="unstyled"
                marginBottom={theme.pxToRem(2)}
                onChange={(e) => setValue(e.target.value)}
              >
                {data
                  ?.filter((a) => (collaborator ? a?.type : !a?.type))
                  ?.map((item, index) => (
                    <option
                      style={{
                        backgroundColor: theme.colors.menuBackground,
                        fontFamily: 'Montserrat Medium',
                      }}
                      key={`service_${item?.id}`}
                      value={item?.id}
                    >
                      {item?.name}
                    </option>
                  ))}
              </Select>
            </Flex>
            <TextInput
              mt="12px"
              h="46px"
              noBorder
              type="date"
              value={paymentDate}
              onChange={(e) => {
                setPaymentDate(e.target.value)
              }}
              label="Data de pagamento"
              padding={theme.pxToRem(10)}
            />
            <Flex mt="32px" mb="16px" alignItems="center">
              <CheckBox
                onChange={(event) => {
                  setValue()
                  setSignature(false)
                  setCollaborator(!collaborator)
                }}
                color="primary"
                borderColor={!collaborator ? 'textMedium' : 'primary'}
                isChecked={collaborator}
                size="lg"
              />
              <Text ml={pxToRem(8)} fontWeight="bold" fontSize="small" color={'textLight'}>
                Dar baixa para outros colaboradores (Ex. Recepcionista)
              </Text>
            </Flex>
          </Flex>
          {isLoading && <Loader />}
          <Text kind="semiBold" fontSize={theme.pxToRem(16)} color="white" mb={theme.pxToRem(8)} mt={theme.pxToRem(16)}>
            {t('EXTRACT')}
          </Text>
          {!isLoading && appointmentsList?.data?.length > 0 ? (
            <Flex w="100%" flexDir="column" mt={theme.pxToRem(16)}>
              <Text kind="semiBold" fontSize={theme.pxToRem(16)} color="white" mb={theme.pxToRem(8)}>
                {t('RESUME')}
              </Text>
              <FinanceResumeCard
                label="Total"
                description="Total à receber"
                value={appointmentsList?.total_comission + appointmentsList?.barber_balance}
                color="success"
                w="100%"
              />
              <Grid
                justifyItems="center"
                templateRows={'repeat(1, 1fr)'}
                templateColumns={'repeat(3, 1fr)'}
                gap={4}
                mt="16px"
              >
                {!signature &&
                  cards(appointmentsList)?.map((item) => (
                    <GridItem w="100%">
                      <FinanceResumeCard
                        label={t(item?.label)}
                        description={t(item?.description)}
                        value={item?.value}
                        color={item?.color}
                        notCurrency={item?.notCurrency}
                      />
                    </GridItem>
                  ))}
              </Grid>
              {signature && (
                <Text mt="12px" mb="12px">
                  {`Total de atendimentos: ${appointmentsList?.data?.length}`}
                </Text>
              )}

              <Table
                loading={isLoading}
                data={appointmentsList?.data?.sort(
                  (a, b) =>
                    parseISO(b?.simple_date ?? b?.payment_date ?? b?.due_at_date ?? b?.date ?? b?.createdAt).getTime() -
                    parseISO(a?.simple_date ?? a?.payment_date ?? a?.due_at_date ?? a?.date ?? a?.createdAt).getTime()
                )}
                columns={columns(signature)}
              />
            </Flex>
          ) : (
            <Text
              kind="normal"
              fontSize={theme.pxToRem(16)}
              color="white"
              mb={theme.pxToRem(8)}
              mt={theme.pxToRem(16)}
              w="100%"
              textAlign="center"
            >
              {t('NO_COMMISSION')}
            </Text>
          )}
          <Flex marginTop={pxToRem(24)} width="full" maxWidth={!isDesktop && 325}>
            <Button
              kind="primary"
              size="block"
              isDisabled={isDisabled || appointmentsList?.data?.length === 0 || mutate.isLoading || isLoading}
              type="submit"
              isLoading={mutate.isLoading}
              onClick={() => createPaidBarber()}
            >
              Dar baixa
            </Button>
          </Flex>
        </Flex>
      </ModalMenu>
    </>
  )
}
