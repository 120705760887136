import React, { useState } from 'react'
import { getDay } from 'date-fns'
import { Text, Icon, Avatar, TextInput } from 'components/atoms'
import { Box, useTheme, Flex } from '@chakra-ui/react'
import { formatPrice } from 'utils'
import { useBreakpoint } from 'services/hooks/use-breakpoint'
import { clearCurrency } from 'utils/clearCurrency'
import { useAppointmentStore, useAppointmentDetailStore } from 'services/stores'
import { useMutation } from 'react-query'
import { useAppointment } from 'services/hooks'

export const ServiceCard = ({
  service,
  onSelect,
  onDelete,
  inList,
  price,
  date,
  isSingleSale,
  reopened,
  index,
  setEnabled,
  recurrent,
  isDetails,
  ...props
}) => {
  const dayNamesShort = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']
  const { isDesktop } = useBreakpoint()
  const theme = useTheme()
  const [edit, setEdit] = useState(false)
  const { serviceArray, setServiceArray, scheduleType, appointmentType } = useAppointmentStore()
  const { updateServiceAppointment } = useAppointment()
  const { selectedAppointment, setSelectedAppointment } = useAppointmentDetailStore()
  const [value, setValue] = useState(formatPrice(price ? price : Number(service?.price || service?.item_value)))
  // const dayOfTheWeek = getDay(new Date(date) || new Date())
  // const isPromotion = useMemo(() => {
  //   return !!service?.promotion_days?.length
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [service, dayOfTheWeek])

  const onChangeValue = (value) => {
    setEnabled(false)
    const changed = (
      selectedAppointment?.status !== 'reopened' ? selectedAppointment?.formatted_services : serviceArray
    )?.map((item) => {
      if (isDetails) {
        if (item?.appointment_service_id === service?.appointment_service_id) {
          return {
            ...item,
            price: value,
          }
        }
        return item
      }
      if (item?.service_id ? item?.service_id === service?.service_id : item?.id === service?.id) {
        return {
          ...item,
          price: value,
        }
      }
      return item
    })
    if (selectedAppointment?.status !== 'reopened') {
      setSelectedAppointment({
        ...selectedAppointment,
        formatted_services: changed,
      })
    } else {
      setServiceArray(changed)
    }
  }

  const mutate = useMutation('change-service-value', (params) => updateServiceAppointment(params))
  return (
    <Box
      display="flex"
      flexDir="row"
      alignItems="center"
      justifyContent="space-between"
      p={theme.pxToRem(16)}
      mb={inList && theme.pxToRem(16)}
      minH="56px"
      w="100%"
      background="cardBackground"
      borderRadius={theme.pxToRem(8)}
      borderWidth={1}
      borderColor="modalBorder"
      {...props}
    >
      <Flex w="90%" flexDir="row" alignItems="center" justifyContent="space-between">
        <Flex alignItems="left" w="50%" flexDir="column">
          {/* <Avatar size="medium" type="service" src={service?.image_url} /> */}

          <Text
            kind="bold"
            color="textLight"
            fontSize={theme.pxToRem(14)}
            mr={theme.pxToRem(8)}
            // ml={theme.pxToRem(16)}
            maxW={theme.pxToRem(isDesktop ? 300 : 70)}
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {service?.name}
          </Text>

          {service?.promotion_days && service?.promotion_price && (
            <Box mt="6px">
              <Text w="100%" fontSize="14px" kind="medium" color="textLight">
                Valor de {formatPrice(service?.promotion_price)} às{' '}
                {service?.promotion_days?.map((i) => dayNamesShort[i])?.join(', ')}
              </Text>
            </Box>
          )}
          {/* {isPromotion && <Icon name="Promo" />} */}
        </Flex>

        {!edit ? (
          <Text kind="bold" color="textLight" w={!isDesktop && '45%'} fontSize={theme.pxToRem(14)}>
            {(((service?.type && service?.type === 'signature') ||
              (appointmentType === 'signature' && service?.type === 'signature')) &&
              scheduleType !== 'package') ||
            service?.service?.type
              ? 'Serviço pelo clube'
              : service?.type === 'individual_service_package'
              ? 'Pacote de serviço'
              : formatPrice(price ? price : Number(service?.price ?? service?.item_value ?? service?.value))}
          </Text>
        ) : (
          <TextInput
            w="25%"
            placeholder="0,00"
            mask="CURRENCY_MASK"
            isReadOnly={false}
            value={
              (selectedAppointment?.status === 'reopened' ? serviceArray : selectedAppointment?.formatted_services)[
                index
              ]?.price
            }
            onChange={(e) => onChangeValue(clearCurrency(e.target.value))}
          />
        )}
        {reopened && !recurrent && (
          <Icon
            mr={!onSelect && theme.pxToRem(!isDesktop ? 6 : 28)}
            name={edit ? 'Check' : 'Pencil'}
            size={16}
            ml="5px"
            color="white"
            onClick={() => {
              if (edit && isDetails) {
                mutate.mutate({
                  params: {
                    appointment_service_id: service?.appointment_service_id,
                    value: selectedAppointment?.formatted_services?.find(
                      (i) => i?.appointment_service_id === service?.appointment_service_id
                    )?.price,
                  },
                })
              }
              setEdit(!edit)
            }}
            cursor="pointer"
          />
        )}

        {!isSingleSale && (
          <Text kind="medium" w={!isDesktop && '20%'} fontSize={theme.pxToRem(14)}>
            {service?.time_required?.substring(0, 5)}
          </Text>
        )}

        {!!onSelect && (
          <Icon cursor="pointer" onClick={() => onSelect(service)} name="ChevronRight" color="primary" size={16} />
        )}
      </Flex>

      {!!onDelete && (
        <Box onClick={() => onDelete(service)}>
          <Icon cursor="pointer" name="Trash" color="baseRed" size={16} mr={theme.pxToRem(4)} />
        </Box>
      )}
    </Box>
  )
}
